/**
 * ReactJS, SCSS
 * Index/Home Page
 * Adam. G
 */

import {React, useState, useEffect} from 'react';
import './index.scss';

/**
 * Custom Components
 */
 import CustomNav from './../../Components/Navbar/nav';
 import Footer from './../../Components/Footer/footer';
 //Lightbox
 import Lightbox from './../../Components/Lightbox/lightbox';
 // Carousel
 import Carousel from './../../Components/Carousel/carousel.js';

/**
 * Imports
 */
// Bootstrap
import { Container, Row, Col, InputGroup, FormControl, Button } from 'react-bootstrap';
// Content Card
import ContentCard from './../../Components/Card/card';
import Alternate from '../../Components/AlternateWelcome/alternate';
// Partners slider
import PartnersSlider from './../../Components/SalesPartners/partners';
// Reviews Slider
import ReviewsSlider from './../../Components/Reviews/reviews';
import LazyLoad from 'react-lazyload';

// Images
import SlideOneRight from "./../../Img/New Content/title-2.jpg";
import SlideTwoLeft from "./../../Img/New Content/title-1.jpg";
import SlideThreeRight from "./../../Img/New Content/title-3.jpg";

function App() {

    useEffect(() => {
        document.title = 'Knaresborough Tyres || Affordable Car, Van & Mortorcycle Tyres, Commercial, Idustrial & Solid Tyres';
    }, []);

    const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
    const [postCode, setPostCode] = useState('');

    function Directions() {
        window.open(`https://www.google.com/maps?saddr=${postCode}&daddr=Knaresborough+Tyre+Services+Ltd`); 
    }

    return (
        <>
            <CustomNav
                activePage={"home"}
                openFindUsLightbox={() => setLightboxIsOpen(true)}
            />
                <Container fluid className="introImages">
                    <Alternate />
                </Container>
                <Container className="contentContainer">
                    <Row>
                        <Col>
                            <PartnersSlider />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="6" md="12" sm="12" className="d-none d-lg-block d-md-block">
                            <div className="findUs">
                                <div className="verticalFindUsAlign">
                                    <p className="directionsText">Directions To Our Knaresborough Location Using Google:</p>
                                    <InputGroup className="mb-3">
                                        {/*<InputGroup.Prepend>
                                            <InputGroup.Text id="basic-addon1">PostCode</InputGroup.Text>
                                        </InputGroup.Prepend>*/}
                                        <FormControl
                                            placeholder="PostCode..."
                                            aria-label="PostCode..."
                                            aria-describedby="basic-addon1"
                                            value={postCode}
                                            onChange={(e) => setPostCode(e.target.value)}
                                        />
                                        <InputGroup.Append>
                                            <Button variant="secondary" onClick={() => Directions()}>Open In Google</Button>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </div>
                            </div>
                        </Col>
                        <Col lg="6" md="12" sm="12">
                            <LazyLoad once>
                                <iframe
                                    title="Address"
                                    width="100%"
                                    height="250"
                                    style={{ 'border': 0 }}
                                    loading="lazy"
                                    allowfullscreen
                                    src="https://www.google.com/maps/embed/v1/place?key=AIzaSyD4AS4Rve3NyVk-YbQn8AmrKufOBP04RSw
                                        &q=Knaresborough+Tyre+Services+Ltd">
                                </iframe>
                            </LazyLoad>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="6" md="6" sm="12">
                            <div className="services_Left">
                                <div className="content">
                                    <h3>We service Cars, Vans &amp; Suv's!</h3>
                                    <hr/>
                                    <p>Facilitating everything and every vehicle from a simple battery change to complete MOTs, exhaust replacements & more. Check out Ripon for our commercial & Industrial.</p>
                                    <Button className="learnMore">Learn More</Button> 
                                </div>     
                            </div>
                        </Col>
                        <Col lg="6" md="6" className="d-none d-lg-block d-md-block">
                            <div className="services_Right" style={{backgroundImage: `url(${SlideOneRight})`, backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center"}}>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="6" md="6" className="d-none d-lg-block d-md-block">
                            <div className="services_Left" style={{backgroundImage: `url(${SlideTwoLeft})`, backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center"}}>       
                            </div>
                        </Col>
                        <Col lg="6" md="6" sm="12">
                            <div className="services_Right">
                                <div className="content">
                                    <h3>In-house wheel alignment</h3>
                                    <hr/>
                                    <p>You're safety is important to us, we have in house state of the art laser alignment equipment to give you complete confidence and ressurance in your vehicles handling.</p>
                                    <Button className="learnMore">Read about our wheel alignment!</Button>  
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="6" md="6" sm="12">
                            <div className="services_Left">
                                <div className="content">
                                    <h3>Same day availability and fitting!</h3>
                                    <hr/>
                                    <p>If we don't have the right size tyre in stock we can get same day delivery to both Knaresborough & Ripon for same day fitting (Subject to time of day &amp; external stock)</p>
                                    <Button className="learnMore">Learn More</Button> 
                                </div>     
                            </div>
                        </Col>
                        <Col lg="6" md="6" className="d-none d-lg-block d-md-block">
                            <div className="services_Right" style={{backgroundImage: `url(${SlideThreeRight})`, backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center"}}>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ReviewsSlider/>
                        </Col>
                    </Row>
                    {/*<Row>
                        <Col>
                            <img src={Brands} className="brandsImage" alt="Tyre Brands We Supply" />
                        </Col>
                    </Row>*/}
                </Container>
            <Footer />
            <Lightbox
                showState={lightboxIsOpen}
                closeLightbox={() => setLightboxIsOpen(false)}
            >
                <div style={{ height: '100%', width: '100%' }}>
                    <iframe
                        title="Address"
                        width="100%"
                        height="100%"
                        style={{ 'border': 0 }}
                        loading="lazy"
                        allowfullscreen
                        src="https://www.google.com/maps/embed/v1/place?key=AIzaSyD4AS4Rve3NyVk-YbQn8AmrKufOBP04RSw
                            &q=Knaresborough+Tyre+Services+Ltd">
                    </iframe>
                </div>
            </Lightbox>
        </>
    );
}

export default App;
