import React from "react";
import { Container, Col, Row, Form, InputGroup, Button } from "react-bootstrap";

import "./alternate.scss";
import SlideOneRight from "./../../Img/New Content/title-2.png";

function Alternate() {
    return (
        <Container fluid className="alternateWelcomeBG" style={{backgroundImage: `url(${SlideOneRight})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center"}}>
            <Container>
                <Col>
                    <Row>
                        <div className="contentContainer">
                            <h1 id="alternateWelcomeH1">Competetively priced family run business</h1>
                            <p>Most competetive prices on Davanti budget tyres, guaranteed! Looking to see what we have in stock? Use our new stock availability checker!</p>
                            
                            <div className="stockCheckInput">
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        placeholder="Your tyre size"
                                        aria-label="Your tyre size"
                                        aria-describedby="basic-addon2"
                                    />
                                    <Button variant="outline-secondary" id="button-addon2">
                                        Search
                                    </Button>
                                </InputGroup>
                            </div>

                            <div className="recruitmentBanner">
                                We're recruiting! Looking for an exciting career in the motor industry, use the link below to find out more!
                                <a href="#">Learn More</a>
                            </div>
                        </div>
                    </Row>
                </Col>
            </Container>
        </Container>
    );
}

export default Alternate;